.contactUs-desktop-container {
  display: flex;
  padding-left: 104px;
  padding-right: 130px;

  .hyperLinkSection-KFC {
    text-decoration: underline;
    color: $Brand-Black;
  }

  .input-container-desktop-textarea {
    width: 90%;
    position: relative;
    display: inline-block;
    padding: 0 0 5px 0;
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 500;
    top: 12px;
    margin-bottom: 13px;
  }

  .input-container-desktop-message {
    width: 120%;
    position: relative;
    display: inline-block;
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 500;
    top: 33px;
    margin-bottom: 55px;
    margin-left: 1px;
  }

  .contactKFC-text {
    margin-left: 7px;
  }
  .contactKFC-list-suggestion {
    top: 40px;
    border-radius: 4px;
    z-index: 99;
    height: auto;
    list-style: none;
    overflow-y: auto;
    width: 100%;
    padding: 0;
    padding-left: 10px;
    background-color: #f5f2ec;

    .contactKFC-suggestion-text {
      font-size: 12px;
      background-color: $Brand-Black;
      color: white;
      font-family: $National2Regular;
      padding: 1% 1% 1% 1%;
      cursor: pointer;

      .suggetion-pin {
        margin-right: 10px !important;
      }
    }
  }

  .material-input.field input:placeholder-shown + label {
    font-size: 10px;
  }
  .material-input.field label {
    font-size: 14px;
  }

  .date-pick .react-datepicker-popper {
    width: 60% !important;
    left: 0em !important;
  }

  .time-pick {
    position: relative;
    width: 100%;
  }

  .contact-postcode-label {
    margin-top: 10px;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #202124;
    width: 33em;
  }

  .contact-date-label {
    margin-top: 8px;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #202124;
    width: 10em;
    text-transform: capitalize;
  }

  .contact-time-label {
    margin-top: 8px;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #202124;
    width: 13em;
    padding-left: 8%;
    text-transform: capitalize;
  }

  .error-msg {
    color: #ff2e00;
  }

  .error-sub-container {
    display: flex;
  }

  @media #{$breakpoint-above-desktop} {
    padding-left: 143px;
  }

  .contactUs-section-left {
    width: 294px;
    .contactus-header-title {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 38px;
      line-height: 43px;
      letter-spacing: 1px;
      text-transform: $uppercase;
      padding-bottom: 1vw;
      margin-top: 60px;
    }

    .contactus-header-description {
      width: 280px;
      height: 72px;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: $Dark-Gray;
      flex: none;
      order: 1;
      flex-grow: 0;
    }

    .contactUs-header-container {
      .contactUs-icon {
        margin-bottom: 30px;
      }

      .contactUs-header {
        margin-bottom: 20px;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 38px;
        line-height: 43px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $Brand-Black;
      }

      .contactUs-subheader {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $Dark-Gray;
        border-bottom: 1px solid $light-grey;
        padding-bottom: 30px;
      }

      .button-container {
        display: flex;
        flex-direction: column;
        margin-top: 30px;

        .contactus-button {
          padding: 5px 15px;
          margin-bottom: 20px;
          width: 242px;
        }

        .icon {
          margin-right: 10px;
        }
      }
    }
  }

  .contactUs-description-desktop {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    .border-style-touch {
      border-style: solid;
      border-width: thin;
      border-color: #d1cccc;
      background-color: $Mashed-Potato-Gray;
      justify-content: center;
      align-items: center;
      padding: 60px 120px 80px;
      gap: 50px;
      width: 780px;
      @media #{$breakpoint-desktop-small}{
        width: 600px;
        padding: 30px 90px 40px;
      }
    }
    .contactus-body-description {
      width: 780px;
      height: 154px;
      left: 530px;
      top: 834px;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      align-items: flex-end;
      letter-spacing: -0.1px;
      color: $Dark-Gray;
      margin-top: 86px;
      margin-bottom: 75px;
      @media #{$breakpoint-desktop-small}{
        width: 600px;
      }
    }
    .hostedContentFrame {
      margin-bottom: 75px;
      width: 780px;
      @media #{$breakpoint-below-desktop-above-tablet} {
        width: 400px;
      }
    }
  }

  .p-noneContactKFC {
    pointer-events: none;
    border: none;
  }

  .input-container {
    position: relative;
    width: 90%;
    display: inline-block;
    padding: 0 0 5px 0;
    background-color: transparent;
    border: none;
    outline: none;
    z-index: 20;
    font-weight: 500;
    margin: 10px 0;

    input {
      font-family: $National2Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #202124;
    }

    .error-input {
      color: $Red-Orange;
    }
  }

  .date-time-input {
    display: flex;
    width: 107%;
    .react-datepicker__input-container {
      min-width: 200px;
      padding-left: 8px;
    }
    .time-pick,
    .react-datepicker__time-container {
      min-width: 225px !important;
      padding-left: 8px;
    }
  }

  input[type='text'],
  select {
    width: 112%;
    position: relative;
    display: inline-block;
    padding: 0 0 5px 0;
    background-color: transparent;
    border: none;
    border-bottom: solid 1.5px #202124;
    outline: none;
    z-index: 20;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #202124;
    padding-bottom: 1px;
    @extend .commonFont;
  }

  input[type='text']:focus ~ label,
  select:focus ~ label,
  select:valid ~ label,
  input[type='text']:valid ~ label {
    top: -55px;
    font-size: 12px;
    line-height: 22px;
    color: $Dark-Gray;
  }

  .p-none {
    pointer-events: none;
  }
  .select-topic-selection-conatiner {
    display: flex;
    flex-direction: column;

    .select-topic-kfc-selection {
      border: 0;
      border-bottom: 1px solid $Brand-Black;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      background-color: $Mashed-Potato-Gray;
      color: $Brand-Black;
      padding-bottom: 10px;
      margin-top: 15px;
      background-repeat: no-repeat;
      background-position: right;
      cursor: unset;
    }

    .select-topic-kfc-option-conatiner {
      display: flex;
      width: 46%;
      position: absolute;
      margin-top: 49px;
      flex-direction: column;
      background: $Brand-Black;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      padding: 10px 0 20px 0;

      @media #{$large-only } {
        width: 38%;
      }

      @media #{$breakpoint-below-tablet } {
        width: 36%;
      }

      @media #{$breakpoint-between-tablet-desktop} {
        width: 44%;
      }

      .select-topic-kfc-option {
        height: 36px;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $white;
        padding: 5px 14px;
        cursor: pointer;
      }

      .select-topic-kfc-option:hover {
        height: 36px;
        width: 100%;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        background: #c23693;
        color: $white;
        padding: 5px 14px;
        cursor: pointer;
      }
    }
  }

  .contactKFC-section-right {
    margin-left: 120px;
    width: 780px;
    margin-top: 50px;
    @media #{$breakpoint-above-medium-tablet}{
			margin-left: 90px;
		}
		@media #{$breakpoint-desktop-small}{
			margin-left: 30px;
		}
    .touch-title {
      width: 94px;
      height: 25px;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #202124;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-bottom: 35px;
    }

    @media #{$breakpoint-above-desktop} {
      margin-left: 120px;
      width: 1000px;
    }

    .contactUs-title {
      margin-top: 60px;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $Brand-Black;
    }

    .select-topic-container {
      background: $Mashed-Potato-Gray;
      border-radius: 3px;
      padding: 30px 60px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;

      .select-topic-label {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $Brand-Black;
        margin-bottom: 0px;
      }
    }

    .contactUs-questions-container {
      display: flex;
      flex-direction: column;

      .questions-form-header {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $Brand-Black;
        margin-bottom: 0px;
      }

      .questions-form-subheader {
        margin-top: 10px;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $Brand-Black;
      }

      .questions-form-container1 {
        background: $Mashed-Potato-Gray;
        border-radius: 3px;
        padding: 30px 60px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        .container1-header {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: $Brand-Black;
          margin-bottom: 0px;
        }

        .container1-input {
          height: 94px;
          margin-top: 15px;
          border: 1px solid $Dark-Gray;
          box-sizing: border-box;
          border-radius: 3px;
          background: $Mashed-Potato-Gray;
          padding: 8px 15px;
        }

        .container1-input::placeholder {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: $Brand-Black;
          opacity: 0.5;
        }
      }

      .questions-form-container2 {
        background: $Mashed-Potato-Gray;
        border-radius: 3px;
        padding: 30px 60px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        .container2-header {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: $Brand-Black;
          margin-bottom: 0px;
        }

        .radio-container {
          display: flex;
          flex-direction: column;

          .form-radio-wrapper {
            display: flex;
            flex-direction: row;
            margin-top: 15px;
            align-items: center;

            input[type='radio'] {
              margin-right: 10px;
              appearance: none;
              height: 16px;
              width: 16px;
              background-color: transparent;
              border-radius: 100%;
              cursor: pointer;
              outline: none;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid $Dark-Gray;
              box-sizing: border-box;
            }

            input[type='radio']::after {
              content: '•';
              color: $Brand-Black;
              font-size: 2em;
              display: none;
              margin: 0 1.5px 4.7px 1.5px;
            }

            input[type='radio']:checked {
              background-color: inherit;
            }

            input[type='radio']:checked::after {
              display: block;
            }

            span {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 24px;
              letter-spacing: -0.1px;
              color: $Brand-Black;
            }
          }
        }
      }

      .questions-form-container3 {
        background: $Mashed-Potato-Gray;
        border-radius: 3px;
        padding: 30px 60px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        .container3-header-block {
          display: flex;
          justify-content: space-between;

          .mandatory-note {
            font-family: $National2Regular;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0px;
            color: $Dark-Gray;
            margin: 0px;
            padding-top: 5px;
          }

          .container3-header {
            font-family: $National2Condensed;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 25px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: $Brand-Black;
            margin-bottom: 15px;
          }
        }

        .input-conatiner {
          margin-bottom: 10px;
        }

        .input {
          background: $Mashed-Potato-Gray;
        }

        @-moz-document url-prefix() {
          .input::-moz-placeholder {
            color: transparent;
          }
        }
      }

      .captcha {
        margin-top: 30px;
        align-self: center;
      }

      .button-container {
        margin-top: 40px;
        margin-bottom: 120px;
        display: flex;
        align-self: center;

        .contactus-button {
          padding: 5px 15px;
          width: 124px;
          height: 44px;
          margin: 0 9px;
        }
      }
    }
  }

  .contactUs-section-right {
    margin-left: 120px;
    width: 780px;
    margin-top: 100px;
    border-top: 2px solid $Brand-Black;

    @media #{$breakpoint-above-desktop} {
      margin-left: 288px;
      width: 1000px;
    }

    .contactUs-title {
      margin-top: 60px;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $Brand-Black;
    }

    .select-topic-container {
      background: $Mashed-Potato-Gray;
      border-radius: 3px;
      padding: 30px 60px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;

      .select-topic-label {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $Brand-Black;
        margin-bottom: 0px;
      }
    }

    .contactUs-questions-container {
      display: flex;
      flex-direction: column;

      .questions-form-header {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $Brand-Black;
        margin-bottom: 0px;
      }

      .questions-form-subheader {
        margin-top: 10px;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $Brand-Black;
      }

      .questions-form-container1 {
        background: $Mashed-Potato-Gray;
        border-radius: 3px;
        padding: 30px 60px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        .container1-header {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: $Brand-Black;
          margin-bottom: 0px;
        }

        .container1-input {
          height: 94px;
          margin-top: 15px;
          border: 1px solid $Dark-Gray;
          box-sizing: border-box;
          border-radius: 3px;
          background: $Mashed-Potato-Gray;
          padding: 8px 15px;
        }

        .container1-input::placeholder {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: $Brand-Black;
          opacity: 0.5;
        }
      }

      .questions-form-container2 {
        background: $Mashed-Potato-Gray;
        border-radius: 3px;
        padding: 30px 60px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        .container2-header {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: $Brand-Black;
          margin-bottom: 0px;
        }

        .radio-container {
          display: flex;
          flex-direction: column;

          .form-radio-wrapper {
            display: flex;
            flex-direction: row;
            margin-top: 15px;
            align-items: center;

            input[type='radio'] {
              margin-right: 10px;
              appearance: none;
              height: 16px;
              width: 16px;
              background-color: transparent;
              border-radius: 100%;
              cursor: pointer;
              outline: none;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid $Dark-Gray;
              box-sizing: border-box;
            }

            input[type='radio']::after {
              content: '•';
              color: $Brand-Black;
              font-size: 2em;
              display: none;
              margin: 0 1.5px 4.7px 1.5px;
            }

            input[type='radio']:checked {
              background-color: inherit;
            }

            input[type='radio']:checked::after {
              display: block;
            }

            span {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 24px;
              letter-spacing: -0.1px;
              color: $Brand-Black;
            }
          }
        }
      }

      .questions-form-container3 {
        background: $Mashed-Potato-Gray;
        border-radius: 3px;
        padding: 30px 60px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        .container3-header-block {
          display: flex;
          justify-content: space-between;

          .mandatory-note {
            font-family: $National2Regular;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0px;
            color: $Dark-Gray;
            margin: 0px;
            padding-top: 5px;
          }

          .container3-header {
            font-family: $National2Condensed;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 25px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: $Brand-Black;
            margin-bottom: 15px;
          }
        }

        .input-conatiner {
          margin-bottom: 10px;
        }

        .input {
          background: $Mashed-Potato-Gray;
        }

        @-moz-document url-prefix() {
          .input::-moz-placeholder {
            color: transparent;
          }
        }
      }

      .captcha {
        margin-top: 30px;
        align-self: center;
      }

      .button-container {
        margin-top: 40px;
        margin-bottom: 120px;
        display: flex;
        align-self: center;

        .contactus-button {
          padding: 5px 15px;
          width: 124px;
          height: 44px;
          margin: 0 9px;
        }
      }
    }
  }
}

.contactUs-mobile-container {
  .hostedContentFrame {
    @media #{$mobile-only} {
      margin-bottom: 20px;
    }
  }

  .material-input.field input:placeholder-shown + label {
    font-size: 10px;
  }

  .hyperLinkSection-KFC {
    text-decoration: underline;
    color: $Brand-Black;
  }

  .date-pick .react-datepicker-popper {
    width: 82% !important;
    left: 2em !important;
    margin-top: 10px !important;
  }

  .time-pick,
  .react-datepicker__time-container {
    min-width: 255px !important;
  }

  .time-pick,
  .react-datepicker__triangle {
    left: 120px !important;
  }

  .contactKFC-text {
    margin-left: 7px;
  }
  .contactKFC-list-suggestion {
    top: 40px;
    border-radius: 4px;
    z-index: 99;
    height: auto;
    list-style: none;
    overflow-y: auto;
    width: 100%;
    padding: 0;
    padding-left: 10px;
    background-color: #f5f2ec;

    .contactKFC-suggestion-text {
      font-size: 12px;
      background-color: $Brand-Black;
      color: white;
      font-family: $National2Regular;
      padding: 1% 1% 1% 1%;
      cursor: pointer;

      .suggetion-pin {
        margin-right: 10px !important;
      }
    }
  }
  .contact-postcode-label {
    margin-top: 31px;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #202124;
    width: 23em;
  }
  .contact-date-label {
    margin-top: 31px;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #202124;
    width: 23em;
    text-transform: capitalize;
  }
  .contact-time-label {
    margin-top: 31px;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: #202124;
    width: 13em;
    text-transform: capitalize;
  }
  .error-msg {
    color: #ff2e00;
  }
  .error-sub-container {
    display: flex;
  }
  .input-container-textarea {
    width: 95%;
    position: relative;
    display: inline-block;
    padding: 0 0 5px 0;
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 500;
  }
  input[type='text'],
  select {
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 0 0 5px 0;
    background-color: transparent;
    border: none;
    outline: none;
    z-index: 0;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #202124;
    @extend .commonFont;
  }
  .input-container {
    margin: 10px 0;
    width: 95%;
    position: relative;
    display: inline-block;
    padding: 0 0 5px 0;
    background-color: transparent;
    border: none;
    border-bottom: solid 1.5px #202124;
    outline: none;
    z-index: 20;
    font-weight: 500;
    margin-top: -23px;

    input {
      font-family: $National2Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #202124;
      border: none;
    }

    *:focus-visible {
      outline: none !important;
    }

    .error-input {
      color: $Red-Orange;
    }

    .date-pick {
      border: none;
      border-bottom: solid 1.5px #202124;
    }
  }
  .contactUs-mobile-header {
    margin-bottom: 40px;
    padding-right: 20px;
    padding-left: 20px;

    .contactus-header-title {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 38px;
      line-height: 43px;
      letter-spacing: 1px;
      text-transform: $uppercase;
      padding-bottom: 1vw;
      margin-top: 15px;
    }

    .contactus-header-description {
      @media #{$breakpoint-ipad-12-pro-portrait} {
        width: 800px;
      }
      @media #{$breakpoint-ipad-pro-12-landscape} {
        width: 800px;
      }
      @media #{$breakpoint-ipad-landscape} {
        width: 800px;
      }
      @media #{$breakpoint-ipad-air-portrait} {
        width: 800px;
      }
      @media #{$breakpoint-ipad-mini-portrait} {
        width: 726px;
      }
      width: 280px;
      height: 72px;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: $Dark-Gray;
      flex: none;
      order: 1;
      flex-grow: 0;
    }

    .contactUs-icon {
      margin-bottom: 20px;
    }

    .contactUs-header {
      margin-bottom: 10px;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $Brand-Black;
    }

    .contactUs-subheader {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: $Dark-Gray;
      border-bottom: 1px solid $light-grey;
      padding-bottom: 30px;
    }

    .button-container {
      display: flex;
      flex-direction: row;
      margin-top: 30px;
      @media #{$mobile-only} {
        flex-wrap: wrap;
      }
      .contactus-button {
        width: 160px;
        margin-bottom: 20px;
        padding: 5px 5px;
      }

      .space {
        width: 15px;
      }

      .icon {
        margin-right: 10px;
      }
    }
  }

  .contactUs-section-body {
    .contactUs-title {
      margin-top: 60px;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 22px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $Brand-Black;
      padding-right: 20px;
      padding-left: 20px;
    }

    .border-style-touch-mobile {
      border-style: solid;
      border-width: thin;
      border-color: #d1cccc;
      background-color: $Mashed-Potato-Gray;
      justify-content: center;
      align-items: center;
      padding: 21px 14px 14px 14px;
      grid-gap: 50px;
      gap: 50px;
      margin-left: 6px;
      margin-right: 6px;
      margin-top: 10px;
    }
    .contactus-body-description {
      width: 713px;
      height: 154px;
      left: 530px;
      top: 834px;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      display: flex;
      align-items: flex-end;
      letter-spacing: -0.1px;
      color: $Dark-Gray;
    }

    .contactus-body-description-end {
      @media #{$breakpoint-ipad-12-pro-portrait} {
        width: 780px;
      }
      @media #{$breakpoint-ipad-pro-12-landscape} {
        width: 780px;
      }
      @media #{$breakpoint-ipad-landscape} {
        width: 780px;
      }
      @media #{$breakpoint-ipad-air-portrait} {
        width: 780px;
      }
      @media #{$breakpoint-ipad-mini-portrait} {
        width: 742px;
      }
      width: 358px;
      height: 154px;
      left: 530px;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      align-items: flex-end;
      letter-spacing: -0.1px;
      color: $Dark-Gray;
      margin-top: 33px;
      margin-bottom: 322px;
      padding-left: 8px;
    }

    .select-topic-container {
      background: $Mashed-Potato-Gray;
      padding: 30px 20px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;

      .select-topic-label {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $Brand-Black;
        margin-bottom: 0px;
      }
    }

    .contactUs-questions-container {
      display: flex;
      flex-direction: column;

      .questions-form-header {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $Brand-Black;
        padding-right: 20px;
        padding-left: 20px;
        margin-bottom: 0px;
      }

      .questions-form-subheader {
        margin-top: 10px;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $Brand-Black;
        padding-left: 1px;
      }

      .questions-form-container1 {
        background: $Mashed-Potato-Gray;
        padding: 30px 20px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        .container1-header {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $Brand-Black;
          margin-bottom: 0px;
        }

        .container1-input {
          height: 94px;
          margin-top: 20px;
          border: 1px solid $Dark-Gray;
          box-sizing: border-box;
          border-radius: 3px;
          background: $Mashed-Potato-Gray;
          padding: 8px 15px;
        }

        .container1-input::placeholder {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: $Brand-Black;
          opacity: 0.5;
        }
      }

      .questions-form-container2 {
        background: $Mashed-Potato-Gray;
        padding: 30px 20px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        .container2-header {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $Brand-Black;
          margin-bottom: 0px;
        }

        .radio-container {
          margin-bottom: 5px;
          display: flex;
          flex-direction: column;

          .form-radio-wrapper {
            display: flex;
            flex-direction: row;
            margin-top: 15px;
            align-items: center;

            input[type='radio'] {
              margin-right: 10px;
              appearance: none;
              height: 16px;
              width: 16px;
              background-color: transparent;
              border-radius: 100%;
              cursor: pointer;
              outline: none;
              border: 1px solid #000;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            input[type='radio']::after {
              content: '•';
              color: $Brand-Black;
              font-weight: 900;
              font-size: 1.4em;
              display: none;
              margin: 0 1.5px 3px 1.5px;
            }

            input[type='radio']:checked {
              background-color: inherit;
            }

            input[type='radio']:checked::after {
              display: block;
            }

            span {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 24px;
              letter-spacing: -0.1px;
              color: $Brand-Black;
            }
          }
        }
      }

      .questions-form-container3 {
        background: $Mashed-Potato-Gray;
        padding: 30px 20px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        .container3-header {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $Brand-Black;
          margin-bottom: 15px;
        }

        .input-conatiner {
          margin-bottom: 10px;
        }

        .input {
          background: $Mashed-Potato-Gray;
        }

        @-moz-document url-prefix() {
          .input::-moz-placeholder {
            color: transparent;
          }
        }
      }

      .captcha {
        margin-top: 30px;
        align-self: center;
      }

      .button-container {
        margin-top: 40px;
        margin-bottom: 60px;
        display: flex;
        align-self: center;

        .contactus-button {
          padding: 5px 15px;
          width: 124px;
          height: 44px;
          margin: 0 9px;
        }
      }
    }
  }
}

.error-kfc-msg {
  color: #ff2e00;
}
.error-sub-kfc-postcode-container {
  display: flex;
  margin-top: -24px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  font-family: $National2Regular;
}

.error-sub-kfc-postcode-mobile-container {
  display: flex;
  margin-top: -6px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  font-family: $National2Regular;
}

.error-sub-kfc-sortvisit-container {
  display: flex;
  margin-top: -11px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  font-family: $National2Regular;
}

.error-sub-kfc-sortvisit-mobile-container {
  display: flex;
  margin-top: -5px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  font-family: $National2Regular;
}

.error-sub-kfc-visitdate-container {
  display: flex;
  margin-top: 7px;
  margin-bottom: -17px;
  font-size: 14px;
  font-weight: 400;
  font-family: $National2Regular;
}

.error-sub-kfc-ordernumber-container {
  display: flex;
  margin-top: -24px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  font-family: $National2Regular;
}

.error-sub-kfc-textarea-container {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  font-family: $National2Regular;
}

.error-sub-kfc-phone-container {
  display: flex;
  margin-top: -10px;
  margin-bottom: -8px;
  font-size: 14px;
  font-weight: 400;
  font-family: $National2Regular;
}

.error-sub-kfc-phone-mobile-container {
  display: flex;
  margin-top: -20px;
  margin-bottom: -8px;
  font-size: 14px;
  font-weight: 400;
  font-family: $National2Regular;
}

.error-sub-kfc-email-container {
  display: flex;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  font-family: $National2Regular;
}

.date-icon-desktop {
  position: absolute;
  bottom: 40%;
  right: -10%;
  cursor: pointer;
}

.date-icon-mobile {
  position: absolute;
  bottom: 40%;
  right: 1%;
  cursor: pointer;
}

.full-name-common {
  border: 0;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0;
  cursor: text;
  font-family: $National2Regular;
  width: 100%;
  background-color: $Mashed-Potato-Gray;
}

.textarea-desktop {
  -webkit-appearance: none;
  cursor: text;
  padding-bottom: 10px;
  font-family: $National2Regular;
  height: 200px;
  width: 111%;
  border: 1px solid $Dark-Gray;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: $Mashed-Potato-Gray;
}
.textarea-desktop-message {
  -webkit-appearance: none;
  padding: 0;
  cursor: text;
  padding-bottom: 10px;
  font-family: $National2Regular;
  width: 100%;
  border: 1px solid $Dark-Gray;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: $Mashed-Potato-Gray;
  height: 200px;
  padding-left: 12px;
  padding-top: 22px;
  font-size: 14px;
}

.textarea-desktop-message-length {
  -webkit-appearance: none;
  padding: 0;
  cursor: text;
  padding-bottom: 10px;
  font-family: $National2Regular;
  width: 100%;
  border: 1px solid $Dark-Gray;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: $Mashed-Potato-Gray;
  height: 350px;
  padding-right: 2px;
  padding-left: 12px;
  padding-top: 22px;
  font-size: 14px;
}

.textarea-desktop-message-single-link {
  -webkit-appearance: none;
  padding: 0;
  cursor: text;
  padding-bottom: 10px;
  font-family: $National2Regular;
  width: 94%;
  border: 1px solid $Dark-Gray;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: $Mashed-Potato-Gray;
  padding-left: 12px;
  padding-top: 22px;
  font-size: 14px;
}
.textarea-desktop-thanks {
  -webkit-appearance: none;
  padding: 0;
  cursor: text;
  padding-bottom: 10px;
  font-family: $National2Regular;
  width: 111%;
  border: 1px solid $Dark-Gray;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: $Mashed-Potato-Gray;
  padding-top: 18px;
  height: 60px;
  padding-left: 10px;
  font-size: 14px;
}
.textarea-desktop-mobile {
  -webkit-appearance: none;
  padding: 0;
  cursor: text;
  padding-bottom: 10px;
  font-family: $National2Regular;
  height: 200px;
  width: 100%;
  border: 1px solid $Dark-Gray;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: $Mashed-Potato-Gray;
}

.textarea-desktop-message-mobile {
  -webkit-appearance: none;
  cursor: text;
  padding-bottom: 10px;
  font-family: $National2Regular;
  width: 100%;
  border: 1px solid $Dark-Gray;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: $Mashed-Potato-Gray;
  padding-top: 6px;
  padding-left: 5px;
  margin-top: 14px;
}

.textarea-desktop-thanks-mobile {
  -webkit-appearance: none;
  padding: 0;
  cursor: text;
  padding-bottom: 10px;
  font-family: $National2Regular;
  width: 100%;
  border: 1px solid $Dark-Gray;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: $Mashed-Potato-Gray;
  font-size: 14px;
  margin-top: 13px;
  padding-top: 22px;
  height: 60px;
  padding-left: 9px;
}

.full-name-common-mobile {
  border: 0;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0;
  cursor: text;
  padding-bottom: 10px;
  font-family: $National2Regular;
  width: 97%;
  background-color: $Mashed-Potato-Gray;
}

.submit-button-desktop {
  margin-left: 13em;
  margin-top: 3em;
}

.submit-button-mobile {
  margin-left: 6em;
  margin-top: 2em;
}

.error-text {
  padding-top: 6px;
  color: #cc2400;
  font-family: $National2Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

.message-display-text {
  display: flex;
}

.message-display-not-text {
  display: none;
}

.submit-alert-conatiner {
  display: flex;
  flex-direction: column;

  .alert-title {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-transform: unset;
    color: $Brand-Black;
    margin-bottom: 30px;
    align-self: flex-start;
  }

  .alert-message {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    height: 32px;
    font-size: 16px;
    line-height: 20px;
    color: $Brand-Black;
    margin-bottom: 18px;
  }

  .alert-button {
    background-color: $Brand-Red;
    margin: 0;
    font-family: $National2Regular;
    font-size: 15px;
    align-self: flex-end;
    border-radius: 5px;
    width: 115px;
    height: 34px;
  }
}

.conatct-us-alet-modal {
  z-index: $z-index-max + 1;
  width: 100%;

  & .modal-content {
    width: 450px;
    height: 170px;
    left: 38%;
    top: 34%;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);

    @media (min-width: 200px) and (max-width: 1023px) {
      left: 2%;
      width: 96%;
    }

    & .modal-header {
      height: 0;
      padding-top: 10px;
      border-bottom: none;

      .custom-modal-close {
        display: none;
      }
    }

    & .modal-body {
      padding: 0 15px 15px 15px;
      box-shadow: none;
      background-color: $white;
    }
  }
}
